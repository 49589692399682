import { isBrowserSupported } from './utils/is-browser-supported'

// Check if the browser is supported...
if (isBrowserSupported()) {
  // ...and if it is, load the root component lazily. This is because
  // it allows ParcelJS to bundle the root component separately from
  // the rest of the app, so it is not loaded in unsupported environments.
  import('./root')
    .then(({ run }) => { run() })
    .catch((error) => {
      console.error(error)
    })
} else {
  // ...and if it is not, redirect the user to the unsupported page.
  window.location.replace('/unsupported.html')
}
