/**
 * Checks if the browser is supported by the application.
 *
 * Notes:
 *    - As of 11-07-2023, Microsoft Edge Legacy (`EdgeHTML`) (not the same as Microsoft
 * Edge WebView2, builded with Chromium) and Internet Explorer 11 (`Trident`) are
 * not supported.
 *    - The function make use of `String.indexOf()` as `String.includes()` isn't supported
 * by IE11.
 *
 * Read the following documentation for more information:
 * @see https://learn.microsoft.com/en-us/office/dev/add-ins/develop/support-ie-11
 *
 * @returns Returns `true` if the browser is supported, `false` otherwise.
 */
export const isBrowserSupported = (): boolean => !(
  // eslint-disable-next-line @typescript-eslint/prefer-includes
  navigator.userAgent.indexOf('Trident') !== -1 ||

  // eslint-disable-next-line @typescript-eslint/prefer-includes
  navigator.userAgent.indexOf('Edge') !== -1
)
